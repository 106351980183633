<template>
  <a-spin :spinning="spinning">
    <div :class="{disabled: disabled}">
      <a-card class="card" title="业务信息" :bordered="false">
        <div slot="extra">
          <a-affix :offsetTop="50" :style="{ position: 'absolute', top: '15px', left: 0, width: '98%', 'text-align': 'right'}">
            <a-button><router-link :to="{ name: 'CumsImport'}">取消</router-link></a-button>
            <a-divider type="vertical" />
            <a-button :disabled="!isEdit" type="primary" @click="handleExport('goods')">导出</a-button>
            <a-divider type="vertical" />
            <a-button :disabled="!isEdit" type="primary" v-if="form.service_type.includes(0)" @click="handleExport(0)">代理报关委托书</a-button>
            <a-divider type="vertical" v-if="form.service_type.includes(0)"/>
            <a-button :disabled="!isEdit" type="primary" v-if="form.service_type.includes(1)" @click="handleExport(1)">代理运输委托书</a-button>
            <a-divider type="vertical" v-if="form.service_type.includes(1)"/>
            <a-button :disabled="!isEdit" type="primary" v-if="form.service_type.includes(2)" @click="handleExport(2)">代理订舱委托书</a-button>
            <a-divider type="vertical" v-if="form.service_type.includes(2)"/>
            <a-button :disabled="!isEdit" type="primary" v-if="form.service_type.includes(3)" @click="handleExport(3)">供应商仓储通知书</a-button>
            <a-divider type="vertical" v-if="form.service_type.includes(3)"/>
            <a-button :disabled="disabled ? true : false" type="primary" @click="handleSubmit" v-preventReClick>保存</a-button>
          </a-affix>
        </div>
        <a-form-model layout="vertical" :model="form"
                      :rules="rules" ref="form">
          <a-row :gutter="20">
            <a-col :span="6">
              <a-form-model-item label="业务编号">
                <a-input v-model="form.serial_num" disabled placeholder="系统自动生成"/>
              </a-form-model-item>
            </a-col>
            <a-col :span="6">
              <a-form-model-item label="进库核注单号">
                <a-input v-model="form.check_num"/>
              </a-form-model-item>
            </a-col>
            <a-col :span="6">
              <a-form-model-item label="委托客户" prop="auth_agency" :autoLink="false">
                <a-select
                  showSearch
                  label-in-value
                  allowClear
                  placeholder="请选择委托客户"
                  style="width: 100%"
                  v-model="customerInfo"
                  :filter-option="false"
                  :not-found-content="fetching ? undefined : null"
                  @search="getDepartmentCustomer"
                  @change="changeCustomerInfo"
                >
                  <template v-if="fetching" #notFoundContent>
                    <a-spin size="small" />
                  </template>
                  <a-select-option v-for="op in agencyOps" :key="op.value">{{ op.name }}</a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="6">
              <a-form-model-item label="客服员">
                <!-- <a-input disabled v-model="form.customer"/> -->
                <a-select
                  showSearch
                  allowClear
                  placeholder="请选择客服员"
                  style="width: 100%"
                  v-model="form.customer_id"
                >
                  <a-select-option v-for="op in customerOps" :key="op.value">{{ op.name }}</a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="6">
              <a-form-model-item label="客户编号">
                <a-input v-model="form.customer_num" />
              </a-form-model-item>
            </a-col>
            <a-col :span="6">
              <a-form-model-item label="品名">
                <a-input v-model="form.product_name" />
              </a-form-model-item>
            </a-col>
            <a-col :span="6">
              <a-form-model-item label="总体积">
                <a-input-number v-model="form.total_volume" style="width: 372px" />
              </a-form-model-item>
            </a-col>
            <a-col :span="6">
              <a-form-model-item label="船名航次">
                <a-input v-model="form.ship_name" />
              </a-form-model-item>
            </a-col>
            <a-col :span="6">
              <a-form-model-item label="提单号">
                <a-input v-model="form.delivery_num" />
              </a-form-model-item>
            </a-col>
            <a-col :span="6">
              <a-form-model-item label="港口">
                <!-- <a-input v-model="form.port" /> -->
                <a-select
                  showSearch :filter-option="filterOption"
                  allowClear
                  placeholder="请选择港口"
                  style="width: 100%"
                  v-model="form.port_id"
                >
                  <a-select-option v-for="op in portOps" :key="op.value">{{ op.name }}</a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="6">
              <a-form-model-item label="到港日期">
                <a-date-picker v-model="form.arrive_date" class="full-width"></a-date-picker>
              </a-form-model-item>
            </a-col>
            <a-col :span="6">
              <a-form-model-item label="进库日期">
                <a-date-picker v-model="form.store_date" class="full-width"></a-date-picker>
              </a-form-model-item>
            </a-col>
            <a-col :span="6">
              <a-form-model-item label="查验日期">
                <a-date-picker v-model="form.check_date" class="full-width"></a-date-picker>
              </a-form-model-item>
            </a-col>
            <a-col :span="6">
              <a-form-model-item label="送货日期">
                <a-date-picker v-model="form.send_date" class="full-width"></a-date-picker>
              </a-form-model-item>
            </a-col>
            <a-col :span="6">
              <a-form-model-item label="接单日期">
                <a-date-picker v-model="form.receipt_date" class="full-width"></a-date-picker>
              </a-form-model-item>
            </a-col>
            <a-col :span="6">
              <a-form-model-item label="启运日">
                <a-date-picker v-model="form.transport_date" class="full-width"></a-date-picker>
              </a-form-model-item>
            </a-col>
            <a-col :span="6">
              <a-form-model-item label="贸易方式">
                <a-select
                  showSearch :filter-option="filterOption"
                  allowClear
                  placeholder="请选择贸易方式"
                  style="width: 100%"
                  v-model="form.trade_type"
                >
                  <a-select-option v-for="op in tradeOps" :key="op.value">{{ op.name }}</a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="6">
              <a-form-model-item label="海关编号">
                <a-input v-model="form.customs_num" />
              </a-form-model-item>
            </a-col>
            <a-col :span="6">
              <a-form-model-item label="客户提供资料是否完整">
                <a-radio-group v-model="form.data_integrity">
                  <a-radio :value="1">
                    完整
                  </a-radio>
                  <a-radio :value="2">
                    不完整
                  </a-radio>
                </a-radio-group>
              </a-form-model-item>
            </a-col>
            <a-col :span="24">
              <a-form-model-item label="箱型箱号箱量">
                <template v-for="(box, index) in form.box_data">
                  <a-input-group compact :key="index" class="field-item box-item">
                    <a-select
                      showSearch :filter-option="filterOption"
                      placeholder="箱型"
                      style="width: 90px"
                      v-model="box.id"
                      :allowClear="true">
                      <a-select-option v-for="op in boxOps" :key="op.value">{{ op.name }}</a-select-option>
                    </a-select>
                    <a-input v-model="box.box_num" style="width: 120px" placeholder="箱号"/>
                    <a-input-number v-model="box.num" style="width: 100px" placeholder="箱量"/>
                    <a-icon type="close-circle" theme="filled"
                            class="field-item_del" v-if="index > 0"
                            @click="delItem('box_data', index)"/>
                    <a-button type="primary" v-if="index === (form.box_data.length - 1)"
                              style="width: 60px;margin-left: 10px" @click="createItem('box_data')">更多</a-button>
                  </a-input-group>
                </template>
              </a-form-model-item>
            </a-col>
            <a-col :span="6">
              <a-form-model-item label="业务类型">
                <a-select
                  showSearch
                  allowClear
                  placeholder="请选择业务类型"
                  style="width: 100%"
                  v-model="form.business_type"
                >
                  <a-select-option v-for="op in bizTypeOps" :key="op.value">{{ op.name }}</a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="18">
              <a-form-model-item label="服务类型">
                <a-checkbox-group v-model="form.service_type">
                  <a-checkbox :value="0">代理报关</a-checkbox>
                  <a-checkbox :value="1">代理运输</a-checkbox>
                  <a-checkbox :value="2">代理订舱</a-checkbox>
                  <a-checkbox :value="3">供应商仓储</a-checkbox>
                  <a-checkbox :value="4">代理换单</a-checkbox>
                  <a-checkbox :value="5">本仓服务</a-checkbox>
                </a-checkbox-group>
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row :gutter="20" type="flex" justify="start">
            <template v-if="form.service_type.includes(0)">
              <a-col :span="6">
                <a-form-model-item label="报关票数">
                  <a-input-number v-model="form.cus_clear_num" style="width: 372px" />
                </a-form-model-item>
              </a-col>
              <a-col :span="6">
                <a-form-model-item label="委托报关日期">
                  <a-date-picker v-model="form.customs_date" class="full-width"></a-date-picker>
                </a-form-model-item>
              </a-col>
              <a-col :span="6">
                <a-form-model-item label="报关行">
                  <a-select
                    showSearch
                    allowClear
                    placeholder="请选择报关行"
                    style="width: 100%"
                    @change="handleCompChange('cus_contact_name', ...arguments)"
                    v-model="form.customs_comp"
                  >
                    <a-select-option v-for="op in customsCompOps" :key="op.name" :ops="JSON.stringify(op)">{{ op.name }}</a-select-option>
                  </a-select>
                </a-form-model-item>
              </a-col>
              <a-col :span="6">
                <a-form-model-item label="报关行联系人">
                  <a-auto-complete
                    v-model="form.cus_contact_name"
                    style="width: 100%"
                    placeholder="请选择报关行联系人"
                    :filterOption="handleAutoSearch"
                    @select="handleContactChange('cus_contact_phone', ...arguments)"
                  >
                    <template slot="dataSource">
                      <a-select-option v-for="op in contacts.cus_contact_name" :key="op.phone" :value="op.name">{{ op.name }}</a-select-option>
                    </template>
                  </a-auto-complete>
                </a-form-model-item>
              </a-col>
              <a-col :span="6">
                <a-form-model-item label="报关行联系电话">
                  <a-input v-model="form.cus_contact_phone" />
                </a-form-model-item>
              </a-col>
              <a-col :span="6">
                <a-form-model-item label="报检">
                  <a-switch checked-children="是" un-checked-children="否" v-model="form.customs_check"/>
                </a-form-model-item>
              </a-col>
            </template>
            <template v-if="form.service_type.includes(1)">
              <a-col :span="6">
                <a-form-model-item label="运输车队">
                  <a-select
                    showSearch
                    allowClear
                    placeholder="请选择运输车队"
                    style="width: 100%"
                    @change="handleCompChange('trans_contact', ...arguments)"
                    v-model="form.trans_comp"
                  >
                    <a-select-option v-for="op in transCompOps" :key="op.name" :ops="JSON.stringify(op)">{{ op.name }}</a-select-option>
                  </a-select>
                </a-form-model-item>
              </a-col>
              <a-col :span="6">
                <a-form-model-item label="车队联系人">
                  <a-auto-complete
                    v-model="form.trans_contact"
                    style="width: 100%"
                    placeholder="请选择车队联系人"
                    :filterOption="handleAutoSearch"
                    @select="handleContactChange('trans_phone', ...arguments)"
                  >
                    <template slot="dataSource">
                      <a-select-option v-for="op in contacts.trans_contact" :key="op.phone" :value="op.name">{{ op.name }}</a-select-option>
                    </template>
                  </a-auto-complete>
                </a-form-model-item>
              </a-col>
              <a-col :span="6">
                <a-form-model-item label="车队联系电话">
                  <a-input v-model="form.trans_phone" />
                </a-form-model-item>
              </a-col>
              <a-col :span="6">
                <a-form-model-item label="运输起运地">
                  <a-input v-model="form.departure" />
                </a-form-model-item>
              </a-col>
              <a-col :span="6">
                <a-form-model-item label="运输目的地">
                  <!-- <a-input v-model="form.destination" /> -->
                  <a-select
                    showSearch
                    allowClear
                    placeholder="请选择运输目的地"
                    style="width: 100%"
                    @change="handleCompChange('receiver', ...arguments)"
                    v-model="form.destination"
                  >
                    <a-select-option v-for="op in destinationOps" :key="op.name" :ops="JSON.stringify(op)">{{ op.name }}</a-select-option>
                  </a-select>
                </a-form-model-item>
              </a-col>
              <a-col :span="6">
                <a-form-model-item label="收货人">
                  <!-- <a-input v-model="form.receiver" /> -->
                  <a-auto-complete
                    v-model="form.receiver"
                    style="width: 100%"
                    placeholder="请选择收货人"
                    :filterOption="handleAutoSearch"
                    @select="handleContactChange('receive_phone', ...arguments)"
                  >
                    <template slot="dataSource">
                      <a-select-option v-for="op in contacts.receiver" :key="op.phone" :value="op.name">{{ op.name }}</a-select-option>
                    </template>
                  </a-auto-complete>
                </a-form-model-item>
              </a-col>
              <a-col :span="6">
                <a-form-model-item label="收货人联系电话">
                  <a-input v-model="form.receive_phone" />
                </a-form-model-item>
              </a-col>
            </template>
            <template v-if="form.service_type.includes(2)">
              <a-col :span="6">
                <a-form-model-item label="订舱供应商">
                  <a-select
                    showSearch
                    allowClear
                    placeholder="请选择订舱供应商"
                    style="width: 100%"
                    @change="handleCompChange('order_contact', ...arguments)"
                    v-model="form.order_supplier"
                  >
                    <a-select-option v-for="op in supplierOps" :key="op.name" :ops="JSON.stringify(op)">{{ op.name }}</a-select-option>
                  </a-select>
                </a-form-model-item>
              </a-col>
              <a-col :span="6">
                <a-form-model-item label="订舱联系人">
                  <a-auto-complete
                    v-model="form.order_contact"
                    style="width: 100%"
                    placeholder="请选择订舱联系人"
                    :filterOption="handleAutoSearch"
                    @select="handleContactChange('order_phone', ...arguments)"
                  >
                    <template slot="dataSource">
                      <a-select-option v-for="op in contacts.order_contact" :key="op.phone" :value="op.name">{{ op.name }}</a-select-option>
                    </template>
                  </a-auto-complete>
                </a-form-model-item>
              </a-col>
              <a-col :span="6">
                <a-form-model-item label="订舱联系电话">
                  <a-input v-model="form.order_phone" />
                </a-form-model-item>
              </a-col>
            </template>
            <template v-if="form.service_type.includes(3)">
              <a-col :span="6">
                <a-form-model-item label="仓库">
                  <a-select
                    showSearch
                    allowClear
                    placeholder="请选择仓库"
                    style="width: 100%"
                    @change="handleCompChange('storage_contact', ...arguments)"
                    v-model="form.storage_name"
                  >
                    <a-select-option v-for="op in warehouseOps" :key="op.name" :ops="JSON.stringify(op)">{{ op.name }}</a-select-option>
                  </a-select>
                </a-form-model-item>
              </a-col>
              <a-col :span="6">
                <a-form-model-item label="仓库联系人">
                  <a-auto-complete
                    v-model="form.storage_contact"
                    style="width: 100%"
                    placeholder="请选择仓库联系人"
                    :filterOption="handleAutoSearch"
                    @select="handleContactChange('storage_phone', ...arguments)"
                  >
                    <template slot="dataSource">
                      <a-select-option v-for="op in contacts.storage_contact" :key="op.phone" :value="op.name">{{ op.name }}</a-select-option>
                    </template>
                  </a-auto-complete>
                </a-form-model-item>
              </a-col>
              <a-col :span="6">
                <a-form-model-item label="仓库联系人电话">
                  <a-input v-model="form.storage_phone" />
                </a-form-model-item>
              </a-col>
            </template>
            <template v-if="form.service_type.includes(4)">
              <a-col :span="24">
                <a-form-model-item label="船公司">
                  <template v-for="(item, index) in form.ship_com_ids">
                    <a-input-group compact :key="index" class="field-item ship-item">
                      <!-- <a-auto-complete
                        v-model="form.ship_com_ids[index]"
                        style="width: 200px"
                        placeholder="请选择船公司"
                        :filterOption="handleAutoSearch"
                      >
                        <template slot="dataSource">
                          <a-select-option v-for="op in shipComOps" :key="op.name">{{ op.name }}</a-select-option>
                        </template>
                      </a-auto-complete> -->
                      <a-select
                        v-model="form.ship_com_ids[index]"
                        style="width: 200px"
                        placeholder="请选择船公司"
                        showSearch allowClear
                      >
                        <a-select-option v-for="op in shipComOps" :key="op.value">{{ op.name }}</a-select-option>
                      </a-select>
                      <a-icon type="close-circle" theme="filled"
                              class="field-item_del" v-if="index > 0"
                              @click="delItem('ship_com_ids', index)"/>
                      <a-button type="primary" v-if="index === (form.ship_com_ids.length - 1)"
                                style="width: 60px;margin-left: 10px" @click="createItem('ship_com_ids')">更多</a-button>
                    </a-input-group>
                  </template>
                </a-form-model-item>
              </a-col>
              <a-col :span="6">
                <a-form-model-item label="换单完成时间">
                  <a-date-picker v-model="form.finish_date" class="full-width"></a-date-picker>
                </a-form-model-item>
              </a-col>
              <a-col :span="6">
                <a-form-model-item label="押箱">
                  <a-switch checked-children="是" un-checked-children="否" v-model="form.mortgage"/>
                </a-form-model-item>
              </a-col>
            </template>
          </a-row>
          <a-row :gutter="20">
            <a-col :span="12">
              <a-form-model-item label="备注">
                <a-textarea v-model="form.remark"></a-textarea>
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item label="反馈备注">
                <a-textarea v-model="form.feed_back" disabled></a-textarea>
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-form-model>
      </a-card>
      <a-card class="card" title="其它信息" :bordered="false">
        <div slot="extra">
          <a-affix :style="{ position: 'absolute', top: '15px', left: 0, width: '25%', 'text-align': 'right'}">
            <a-upload
              v-if="this.form.id"
              :beforeUpload="beforeUpload"
              :customRequest="e => handleUpload(e, record)"
              :showUploadList="false">
              <a-button type="primary" :loading="uploadLoading">上传文件（可拖拉）</a-button>
            </a-upload>
          </a-affix>
        </div>
        <a-tabs :activeKey="activeKey" @change="key => {activeKey = key}">
          <a-tab-pane tab="货物信息" key="1">
            <edit-table
              scroll
              :operate="{ add: true, del: true, alldel: true }"
              :columns="goodsColumns" :sourceData="goodsData"
              @getTableDate="getGoodsData">
            </edit-table>
          </a-tab-pane>
          <a-tab-pane tab="费用信息" key="2" forceRender>
            <a-tabs default-active-key="a">
              <a-tab-pane key="1" tab="应收费用">
                <edit-table
                  scroll
                  :columns="chargeFeeColumns"
                  :sourceData="chargeFeeData"
                  :feeStatusFlag="true"
                  @getTableDate="getChargeFeeData"
                  @getTableDateError="getTableDateError"></edit-table>
              </a-tab-pane>
              <a-tab-pane key="b" tab="应付费用">
                <edit-table
                  scroll
                  ref="order_pay_fee"
                  :columns="payFeeColumns"
                  :sourceData="payFeeData"
                  :feeStatusFlag="true"
                  @getTableDate="getPayFeeData"
                  @getTableDateError="getTableDateError"></edit-table>
              </a-tab-pane>
            </a-tabs>
          </a-tab-pane>
          <template slot="tabBarExtraContent">
            <template v-if="activeKey === '2'">
              <span style="margin-right: 20px">应收：{{ totalChargeFee.toFixed(2) }} </span>
              <span style="margin-right: 20px"> 应付：{{ totalPayFee.toFixed(2) }} </span>
              <span style="margin-right: 100px"> 利润：{{ (totalChargeFee - totalPayFee).toFixed(2) }} </span>
              <!-- <a-button type="primary" :disabled="!isEdit" @click="getPayQrcode">
                现金收费
              </a-button> -->
            </template>
          </template>
        </a-tabs>
      </a-card>
      <pay-qrcode :visible.sync="payQrcodeVisible" v-if="getQrcodeParams.id"
                  :getQrcodeFun="getCumsPayQrcode" :params="getQrcodeParams">123</pay-qrcode>
    </div>
  </a-spin>
</template>

<script>
// import Vue from 'vue'
import { getCommonOptions, getCustomer, getSupplier, downloadExcel } from '@/api/common'
import { getPayTargetList } from '@/api/wms/sale'
import { saveCumsBusinessInfo, getCumsBusinsessDetail, getCumsMetaOption, getCumsPayQrcode,
  exportCmsLetter, exportCmsGoodsInfo, importGoodsDetail } from '@/api/cums'
import { EditTable } from '@/components'
import { filterOption } from '@/utils/util'
import moment from 'moment';
import PayQrcode from '@/views/components/PayQrcode'
import preventReClick from '@/utils/preventReClick' // 防多次点击，重复提交
import debounce from 'lodash/debounce';
export default {
  components: {
    EditTable, PayQrcode, preventReClick
  },
  data() {
    this.lastFetchId = 0;
    this.getDepartmentCustomer = debounce(this.getDepartmentCustomer, 800);
    return {
      form: {},
      spinning: false,
      customerInfo: undefined,
      fetching: false,
      sourceForm: {
        id: null,
        serial_num: '',
        // customer: Vue.ls.get('LOGIN_NAME'),
        customer_id: null,
        product_name: '',
        check_num: '',
        auth_agency: null,
        ship_name: '',
        delivery_num: '',
        delivery_man: '',
        port_id: null,
        arrive_date: null,
        store_date: null,
        check_date: null,
        send_date: null,
        trade_type: null,
        customs_num: '',
        box_data: [{ id: null, num: null, box_num: '' }],
        customer_num: '',
        business_type: null,
        service_type: [],
        // 代理报关
        customs_date: null,
        customs_comp: '',
        cus_contact_name: '',
        cus_contact_phone: '',
        customs_check: false,
        // 代理运输
        trans_comp: '',
        departure: '',
        destination: '',
        trans_contact: '',
        trans_phone: '',
        receiver: '',
        receive_phone: '',
        // 代理订舱
        order_supplier: '',
        order_contact: '',
        order_phone: '',
        // 供应商仓库
        storage_name: '',
        storage_contact: '',
        storage_phone: '',
        // 代理换单
        ship_com_ids: [null],
        mortgage: false,
        finish_date: null,
        remark: '',
        feed_back: '',
        data_integrity: null
      },
      isEdit: false,
      disabled: false,
      activeKey: '1',
      boxOps: [],
      bizTypeOps: [], // 业务类型
      agencyOps: [],
      customsCompOps: [],
      transCompOps: [],
      supplierOps: [],
      warehouseOps: [],
      portOps: [],
      tradeOps: [],
      contacts: {},
      customerOps: [],
      destinationOps: [],
      // customerContactOps: [],
      shipComOps: [],
      unitOps: [],
      chargeFeeOps: [],
      payFeeOps: [],
      goodsData: [],
      feeData: [],
      rules: {
        auth_agency: [
          { required: true, message: '请选择委托客户', trigger: 'blur' }
        ]
      },
      goodsColumns: [
        {
          title: '商品编号',
          dataIndex: 'good_no',
          width: 120
        },
        {
          title: '商品名称',
          dataIndex: 'good_name',
          width: 150
        },
        {
          title: '备案序号',
          dataIndex: 'filings_no',
          width: 150
        },
        {
          title: '商品规格型号',
          dataIndex: 'good_spec',
          width: 150,
          customRender: (text, record, index) => {
            return (
              <a-input value={text} onChange={e => {
                this.goodsData[index]['good_spec'] = e.target.value
              }}><a-icon slot="addonAfter" type="arrow-down" onClick={e => {
                if (text) {
                  this.goodsData.map((v, i) => {
                    if (i > index) {
                      this.goodsData[i]['good_spec'] = text
                    }
                  })
                }
              }}/></a-input>)
          }
        },
        {
          title: '商品料号',
          dataIndex: 'good_material',
          width: 130,
          customRender: (text, record, index) => {
            return (
              <a-input value={text} onChange={e => {
                this.goodsData[index]['good_material'] = e.target.value
              }}></a-input>)
          }
        },
        {
          title: '灌装日期',
          dataIndex: 'filling_date_str',
          width: 150,
          extra: {
            type: 'date',
            default: null
          },
          customRender: (text, record, index) => {
            return (
              <a-input-group compact>
                <a-date-picker style="width: 140px" value={text} onChange={val => {
                  this.goodsData[index]['filling_date'] = val
                  this.goodsData[index]['filling_date_str'] = moment(val).format('YYYY-MM-DD')
                }}></a-date-picker>
              </a-input-group>)
          }
        },
        {
          title: '货物单位',
          dataIndex: 'unit',
          width: 110,
          // extra: {
          //   type: 'autocomplete',
          //   func: getCumsMetaOption,
          //   params: ['cums_meta_info', { type: 6 }],
          //   options: []
          // },
          customRender: (text, record, index) => {
            return (
              <a-input-group compact>
                <a-auto-complete style="width: 50px" value={text}
                filterOption={this.filterOption}
                onChange={val => {
                  this.goodsData[index]['unit'] = val
                }}>
                  <template slot="dataSource">
                    {
                      this.unitOps.map(op => {
                        return (<a-select-option key={op.name}>{op.name}</a-select-option>)
                      })
                    }
                  </template>
                </a-auto-complete>
                <a-icon type="arrow-down" style="width: 36px;background: #fafafa; border: 1px solid #d9d9d9; line-height: 30px;" onClick={e => {
                  if (text) {
                    this.goodsData.map((v, i) => {
                      if (i > index) {
                        this.goodsData[i]['unit'] = text
                      }
                    })
                  }
                }}/>
              </a-input-group>)
          }
        },
        {
          title: '货物数量',
          dataIndex: 'num',
          width: 100,
          extra: {
            type: 'number'
          }
        },
        {
          title: '申报计量单位',
          dataIndex: 'upload_unit',
          width: 120,
          // extra: {
          //   type: 'autocomplete',
          //   func: getCumsMetaOption,
          //   params: ['cums_meta_info', { type: 6 }],
          //   options: []
          // },
          customRender: (text, record, index) => {
            return (
              <a-input-group compact>
                <a-auto-complete style="width: 60px" value={text}
                filterOption={this.filterOption}
                onChange={val => {
                  this.goodsData[index]['upload_unit'] = val
                }}>
                  <template slot="dataSource">
                    {
                      this.unitOps.map(op => {
                        return (<a-select-option key={op.name}>{op.name}</a-select-option>)
                      })
                    }
                  </template>
                </a-auto-complete>
                <a-icon type="arrow-down" style="width: 36px;background: #fafafa; border: 1px solid #d9d9d9; line-height: 30px;" onClick={e => {
                  if (text) {
                    this.goodsData.map((v, i) => {
                      if (i > index) {
                        this.goodsData[i]['upload_unit'] = text
                      }
                    })
                  }
                }}/>
              </a-input-group>)
          }
        },
        {
          title: '申报数',
          dataIndex: 'upload_num',
          width: 100
        },
        {
          title: '法定计量单位',
          dataIndex: 'legal_unit',
          width: 120,
          // extra: {
          //   type: 'autocomplete',
          //   func: getCumsMetaOption,
          //   params: ['cums_meta_info', { type: 6 }],
          //   options: []
          // },
          customRender: (text, record, index) => {
            return (
              <a-input-group compact>
                <a-auto-complete style="width: 60px" value={text}
                filterOption={this.filterOption}
                onChange={val => {
                  this.goodsData[index]['legal_unit'] = val
                }}>
                  <template slot="dataSource">
                    {
                      this.unitOps.map(op => {
                        return (<a-select-option key={op.name}>{op.name}</a-select-option>)
                      })
                    }
                  </template>
                </a-auto-complete>
                <a-icon type="arrow-down" style="width: 36px;background: #fafafa; border: 1px solid #d9d9d9; line-height: 30px;" onClick={e => {
                  if (text) {
                    this.goodsData.map((v, i) => {
                      if (i > index) {
                        this.goodsData[i]['legal_unit'] = text
                      }
                    })
                  }
                }}/>
              </a-input-group>)
          }
        },
        {
          title: '法定数量',
          dataIndex: 'legal_num',
          width: 100
        },
        {
          title: '毛重',
          dataIndex: 'gloss_weight',
          width: 100
        },
        {
          title: '净重',
          dataIndex: 'weight',
          width: 100
        },
        {
          title: '库位',
          dataIndex: 'stock_place',
          width: 100
        },
        {
          title: '备注',
          dataIndex: 'supple_remark',
          width: 200
        }
      ],
      payTypeMap: {
        0: 'pay_target',
        1: 'driver',
        2: 'vehicle',
        3: 'fleet'
      },
      chargeFeeData: [],
      payFeeData: [],
      chargeFeeColumns: [
        {
          title: '费用名称',
          dataIndex: 'fee_name_type',
          width: 150,
          customRender: (text, record, index) => {
            return (
              <a-select style="width: 100%" value={text}
                showSearch
                disabled={record.disabled}
                filterOption={this.filterOption}
                onChange={val => {
                  let value = 0
                  for (const item of this.chargeFeeOps) {
                    if (item.value === val) {
                      value = item.attribution_fee
                    }
                  }
                  this.chargeFeeData[index]['fee_name_type'] = val
                  this.chargeFeeData[index]['fee_class'] = value
                }}>
                  {
                    this.chargeFeeOps.map(op => {
                        return (<a-select-option key={op.value}>{op.name}</a-select-option>)
                    })
                  }
              </a-select>
            )
          }
        },
        {
          title: '费用类型',
          dataIndex: 'fee_class',
          width: 150,
          extra: {
            type: 'select',
            options: [{ value: 0, name: '仓储包干收入' }, { value: 1, name: '报关报检收入' }, { value: 2, name: '代理运输收入' }, { value: 3, name: '租赁服务收入' }],
            disabled: record => {
              return record.disabled
            }
          }
        },
        {
          title: '类型',
          dataIndex: 'fee_type',
          extra: {
            defaultValue: 1,
            hidden: true,
            disabled: record => {
              return record.disabled
            }
          }
        },
        {
          title: '金额',
          dataIndex: 'money',
          width: 100,
          extra: {
            extraFunc: this.calcTotalCharge,
            disabled: record => {
              return record.disabled
            }
          }
        },
        {
          title: '币种',
          dataIndex: 'currency',
          extra: {
            type: 'select',
            defaultValue: 19,
            func: getCommonOptions,
            params: ['currency_type'],
            options: [],
            disabled: record => {
              return record.disabled
            }
          }
        },
        {
          title: '发票号',
          dataIndex: 'invoice_num',
          extra: {
            disabled: record => {
              return record.disabled
            }
          }
        },
        {
          title: '费用状态',
          dataIndex: 'fee_status',
          customRender: (text, record) => {
            if (this.GLOBAL.feeStatusMaps[text] && record.deny_reason) {
              return this.GLOBAL.feeStatusMaps[text].name + `:${record.deny_reason[0].deny_reason}`
            } else if (this.GLOBAL.feeStatusMaps[text]) {
              return this.GLOBAL.feeStatusMaps[text].name
            }
          },
          extra: {
            defaultValue: 1,
            disabled: record => {
              return record.disabled
            }
          }
        },
        {
          title: '是否预收',
          dataIndex: 'is_advance',
          extra: {
            type: 'select',
            options: [
              { name: '非预收', value: 1 },
              { name: '预收', value: 2 }
            ],
            defaultValue: 1,
            disabled: record => {
              return record.disabled
            }
          }
        }
      ],
      payFeeColumns: [
        {
          title: '费用名称',
          dataIndex: 'fee_name_type',
          width: 150,
          customRender: (text, record, index) => {
            return (
              <a-select style="width: 100%" value={text}
                showSearch
                disabled={record.disabled}
                filterOption={this.filterOption}
                onChange={val => {
                  let value = 0
                  for (const item of this.payFeeOps) {
                    if (item.value === val) {
                      value = item.attribution_fee
                    }
                  }
                  this.payFeeData[index]['fee_name_type'] = val
                  this.payFeeData[index]['fee_class'] = value
                }}>
                  {
                    this.payFeeOps.map(op => {
                        return (<a-select-option key={op.value}>{op.name}</a-select-option>)
                    })
                  }
              </a-select>
            )
          }
        },
        {
          title: '费用类型',
          dataIndex: 'fee_class',
          width: 150,
          extra: {
            type: 'select',
            options: [{ value: 0, name: '仓储包干收入' }, { value: 1, name: '报关报检收入' }, { value: 2, name: '代理运输收入' }, { value: 3, name: '租赁服务收入' }],
            disabled: record => {
              return record.disabled
            }
          }
        },
        {
          title: '类型',
          dataIndex: 'fee_type',
          extra: {
            defaultValue: 2,
            hidden: true,
            disabled: record => {
              return record.disabled
            }
          }
        },
        {
          title: '金额',
          dataIndex: 'money',
          extra: {
            extraFunc: this.calcTotalPay,
            disabled: record => {
              return record.disabled
            }
          }
        },
        {
          title: '币种',
          dataIndex: 'currency',
          extra: {
            type: 'select',
            defaultValue: 19,
            func: getCommonOptions,
            params: ['currency_type'],
            options: [],
            disabled: record => {
              return record.disabled
            }
          }
        },
        {
          title: '付款对象',
          dataIndex: 'pay_target_id',
          extra: {
            type: 'select',
            func: getSupplier,
            params: [ { department: 7 } ],
            // flexible: true,
            options: [],
            disabled: record => {
              return record.disabled
            }
          }
        },
        {
          title: '费用状态',
          dataIndex: 'fee_status',
          customRender: (text, record) => {
            if (this.GLOBAL.feeStatusMaps[text] && record.deny_reason) {
              return this.GLOBAL.feeStatusMaps[text].name + `:${record.deny_reason[0].deny_reason}`
            } else if (this.GLOBAL.feeStatusMaps[text]) {
              return this.GLOBAL.feeStatusMaps[text].name
            }
          },
          extra: {
            defaultValue: 1,
            disabled: record => {
              return record.disabled
            }
          }
        },
        {
          title: '是否预付',
          dataIndex: 'is_advance',
          extra: {
            type: 'select',
            options: [
              { name: '非预付', value: 1 },
              { name: '预付', value: 2 }
            ],
            defaultValue: 1,
            disabled: record => {
              return record.disabled
            }
          }
        }
      ],
      totalChargeFee: 0,
      totalPayFee: 0,
      payQrcodeVisible: false,
      getQrcodeParams: {},
      uploadLoading: false
    }
  },
  watch: {
    $route: function(newRoute) {
      this.disabled = false
      if (!this.queryParam && newRoute.name === 'ImportCUR') { // 编辑页路由监控
        this.handleFormReset()
        if (newRoute.params.id === 'create') { // 新增
          this.isEdit = false
          this.$route.meta.title = '新增进库业务'
          this.sourceForm.id = null
          this.form.id = null
        } else {
          this.disabled = newRoute.query.disabled
          console.info('watch')
          this.getEditData(newRoute.params.id)
        }
      }
    }
  },
  mounted() {
    if (this.$route.params.id !== 'create') {
      this.disabled = this.$route.query.disabled === true
      console.info('mounted')
      this.getEditData(this.$route.params.id)
    }
  },
  created() {
    this.handleFormReset()
    this.getFormBasicData()
  },
  methods: {
    getTableDateError() {
      this.$notification['error']({
        message: '提示',
        description: '该订单不可删除！'
      })
    },
    filterOption,
    getCommonOptions,
    moment,
    getCumsPayQrcode,
    handleFormReset() {
      this.form = {}
      for (const key in this.sourceForm) {
        this.$set(this.form, key, this.sourceForm[key])
      }
      this.activeKey = '1'
      this.goodsData = []
      this.chargeFeeData = []
      this.payFeeData = []
      this.customerInfo = undefined
      this.totalChargeFee = 0
      this.totalPayFee = 0
      this.$nextTick(_ => {
        this.$refs.form.clearValidate()
      })
    },
    getEditData(id) {
      this.isEdit = true
      this.spinning = true
      this.$route.meta.title = '编辑进库业务'
      getCumsBusinsessDetail('cums_import', { id: id }).then(v => {
        const record = v.order
        record['arrive_date'] = record['arrive_date'] ? moment(record['arrive_date'], 'YYYY/MM/DD') : null
        record['store_date'] = record['store_date'] ? moment(record['store_date'], 'YYYY/MM/DD') : null
        record['check_date'] = record['check_date'] ? moment(record['check_date'], 'YYYY/MM/DD') : null
        record['send_date'] = record['send_date'] ? moment(record['send_date'], 'YYYY/MM/DD') : null
        record['customs_date'] = record['customs_date'] ? moment(record['customs_date'], 'YYYY/MM/DD') : null
        record['finish_date'] = record['finish_date'] ? moment(record['finish_date'], 'YYYY/MM/DD') : null
        this.form = {
          ...record,
          box_data: record.box_data || this.sourceForm.box_data,
          ship_com_ids: record.ship_com_ids || this.sourceForm.ship_com_ids
        }
        this.customerInfo = {
          key: v.order.auth_agency,
          label: v.order.agency_name
        }
        this.goodsData = v.goods
        // 判断费用状态是否为商务待审核状态,如果是的话,就禁用掉删除按钮
        v.charge_fees.forEach(fee => {
          if (typeof fee.fee_class === 'undefined') {
            fee.fee_class = null
          }
          if ((fee.fee_status > 2 && fee.fee_status !== 4) || (fee.is_balance === 2 && fee.fee_status !== 4) || (fee.is_payment === 2 && fee.fee_status !== 4)) fee.disabled = true
        })
        v.pay_fees.forEach(fee => {
          if (typeof fee.fee_class === 'undefined') {
            fee.fee_class = null
          }
          if ((fee.fee_status > 2 && fee.fee_status !== 4) || (fee.is_balance === 2 && fee.fee_status !== 4) || (fee.is_payment === 2 && fee.fee_status !== 4)) fee.disabled = true
        })
        this.chargeFeeData = v.charge_fees
        this.payFeeData = v.pay_fees
        this.chargeFeeData.forEach(v => {
          this.totalChargeFee += parseFloat(v.money)
        })
        this.payFeeData.forEach(v => {
          this.totalPayFee += parseFloat(v.money)
        })
        // v.charge_fees.forEach(fee => {
        //   if (fee.fee_status > 2 && fee.fee_status !== 4) fee.disabled = true;
        // });
        // v.pay_fees.forEach(fee => {
        //   if (fee.fee_status > 2 && fee.fee_status !== 4) fee.disabled = true;
        // });
        this.spinning = false
      }).catch(_ => {
        this.spinning = false
      })
    },
    getDepartmentCustomer(value) {
      this.lastFetchId += 1;
      const fetchId = this.lastFetchId;
      this.agencyOps = [];
      this.fetching = true;
      getCustomer({
        department: 7,
        customerName: value,
        excludeBlack: 'Y'
      }).then(res => {
        if (fetchId !== this.lastFetchId) {
          return;
        }
        this.agencyOps = res
        this.fetching = false;
      })
    },
    changeCustomerInfo(value) {
      this.customerInfo = value
      this.form['auth_agency'] = value.key
      this.agencyOps = []
      this.fetching = false
    },
    getFormBasicData() {
      getCommonOptions('box_info').then(v => {
        this.boxOps = v
      })
      getCommonOptions('fee', { department: 7, type: 1 }).then(v => {
        this.chargeFeeOps = v
      })
      getCommonOptions('fee', { department: 7, type: 2 }).then(v => {
        this.payFeeOps = v
      })
      // getCommonOptions('customer').then(v => {
      //   this.agencyOps = v
      // })
      getCumsMetaOption('cums_meta_info', { type: 1 }).then(v => {
        this.portOps = v
      })
      getCumsMetaOption('cums_meta_info', { type: 5 }).then(v => {
        this.tradeOps = v
      })
      getCumsMetaOption('cums_meta_info', { type: 6 }).then(v => {
        this.unitOps = v
      })
      getCumsMetaOption('cums_meta_info', { type: 7 }).then(v => {
        this.customerOps = v
      })
      getCumsMetaOption('cums_company', { type: 4 }).then(v => {
        this.customsCompOps = v
      })
      getCumsMetaOption('cums_meta_info', { type: 4 }).then(v => {
        this.bizTypeOps = v
      })
      getCumsMetaOption('cums_meta_info', { type: 2 }).then(v => {
        this.shipComOps = v
      })
      getCumsMetaOption('cums_company', { type: 1 }).then(v => {
        this.transCompOps = v
      })
      getCumsMetaOption('cums_company', { type: 2 }).then(v => {
        this.supplierOps = v
      })
      getCumsMetaOption('cums_company', { type: 3 }).then(v => {
        this.warehouseOps = v
      })
      getCumsMetaOption('cums_company', { type: 5 }).then(v => {
        this.destinationOps = v
      })
    },
    handleCompChange(field, data, option) {
      const ops = option ? JSON.parse(option.data.attrs.ops).contacts : []
      this.$set(this.contacts, field, ops)
      switch (field) {
        case 'trans_contact':
          this.form.trans_contact = ''
          this.form.trans_phone = ''
          break;
        case 'order_contact':
          this.form.order_contact = ''
          this.form.order_phone = ''
          break;
        case 'cus_contact_name':
          this.form.cus_contact_name = ''
          this.form.cus_contact_phone = ''
          break;
        case 'receiver':
          this.form.receiver = ''
          this.form.receive_phone = ''
          break;
        case 'storage_contact':
          this.form.storage_contact = ''
          this.form.storage_phone = ''
          break;
      }
    },
    handleContactChange(field, data, option) {
      this.form[field] = option.key
    },
    createItem(field) { // 增加更多箱型
      if (field === 'box_data') {
        this.form.box_data.push({ id: null, num: null, box_num: '' })
      } else if (field === 'ship_com_ids') {
        this.form[field].push(null)
      } else {
        this.form[field].push('')
      }
    },
    delItem(field, index) {
      this.form[field].splice(index, 1)
    },
    getGoodsData(data) {
      this.goodsData = data
    },
    getChargeFeeData(data) {
      this.chargeFeeData = data
    },
    getPayFeeData(data) {
      this.payFeeData = data
    },
    payTypeChange(value, newData, target, index) {
      const key = this.payTypeMap[value]
      target['pay_target_id'] = null
      this.$nextTick(_ => {
        const optarget = this.$refs['import_pay_fee'].tableOptions
        this.$set(optarget, 'pay_target_id' + index, [])
        getPayTargetList(key).then(v => {
          this.$set(optarget, 'pay_target_id' + index, v)
        })
      })
      return newData
    },
    // filterOption(input, option) {
    //   return (
    //     option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
    //       getFirstLetter(option.componentOptions.children[0].text).toLowerCase().indexOf(input.toLowerCase()) >= 0
    //   );
    // },
    calcTotalCharge(value, newData) {
      let money = 0
      this.chargeFeeData.forEach(v => {
        money += parseFloat(v.money)
      })
      this.totalChargeFee = money
      return newData
    },
    calcTotalPay(value, newData) {
      let money = 0
      this.payFeeData.forEach(v => {
        money += parseFloat(v.money)
      })
      this.totalPayFee = money
      return newData
    },
    handleAutoSearch(inputValue, option) {
      if (inputValue) {
        return option.key.toLowerCase().includes(inputValue.toLowerCase())
      } else {
        return true
      }
    },
    getPayQrcode() {
      this.getQrcodeParams = {
        type: 0,
        id: this.form.id,
        deal_type: 2
      }
      this.payQrcodeVisible = true
    },
    handleExport(type) {
      let exportFunc = exportCmsLetter
      let params = {
        id: this.form.id,
        type: type
      }
      if (type === 'goods') {
        exportFunc = exportCmsGoodsInfo
        params = { id: this.form.id }
      }
      exportFunc('cums_import', params).then(res => {
        if (res !== null) {
          const fileInfo = {
            fileName: res
          }
          downloadExcel(fileInfo).then(downRes => {
            const data = downRes.data
            let fileName = ''
            const contentDisposition = downRes.headers['content-disposition']
            if (contentDisposition) {
              fileName = decodeURI(escape(contentDisposition.split('=')[1]))
            }
            const url = window.URL.createObjectURL(
              new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
            )
            const link = document.createElement('a')
            link.style.display = 'none'
            link.href = url
            link.setAttribute('download', fileName)
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
          })
        }
      })
    },
    handleSubmit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          const data = {
            order: {
              ...this.form,
              arrive_date: this.form.arrive_date ? moment(this.form.arrive_date).valueOf() : null,
              store_date: this.form.store_date ? moment(this.form.store_date).valueOf() : null,
              check_date: this.form.check_date ? moment(this.form.check_date).valueOf() : null,
              send_date: this.form.send_date ? moment(this.form.send_date).valueOf() : null,
              customs_date: this.form.customs_date ? moment(this.form.customs_date).valueOf() : null,
              finish_date: this.form.finish_date ? moment(this.form.finish_date).valueOf() : null,
              receipt_date: this.form.receipt_date ? moment(this.form.receipt_date).valueOf() : null,
              transport_date: this.form.transport_date ? moment(this.form.transport_date).valueOf() : null
            },
            goods: this.goodsData.map(g => {
              const a = { ...g }
              a.filling_date = a.filling_date ? moment(a.filling_date).valueOf() : null
              a.gloss_weight = a.gloss_weight || 0
              a.weight = a.weight || 0
              return a
            }),
            charge_fees: this.chargeFeeData,
            pay_fees: this.payFeeData
          }
          saveCumsBusinessInfo('cums_import', data).then(v => {
              this.$notification['success']({
                message: '提示',
                description: this.isEdit ? '编辑成功' : '创建成功.'
              })
              this.isEdit = true
              this.sourceForm.id = v
              this.form.id = v
              this.handleFormReset()
              this.getEditData(this.form.id);
              // this.$router.push({
              //   name: 'CumsImport',
              //   params: { ...data.order,
              //     arrive_date: data.order.arrive_date ? moment(data.order.arrive_date).format('YYYY/MM/DD') : null,
              //     store_date: data.order.store_date ? moment(data.order.store_date).format('YYYY/MM/DD') : null,
              //     check_date: data.order.check_date ? moment(data.order.check_date).format('YYYY/MM/DD') : null },
              //   query: {
              //     isEdit: this.isEdit
              //   }
              // })
            })
        } else {
          return false;
        }
      })
    },
    beforeUpload(file) {
      const fileType = ['xls', 'xlsx']
      const suffix = file.name.split('.')[1]
      if (fileType.indexOf(suffix) === -1) {
        this.$notification['error']({
          message: '提示',
          description: '只能上传word文件、pdf文件、excel文件、图片文件、压缩文件'
        })
        return false
      }
      if (file.size / 1024 / 1024 > 10) {
        this.$notification['error']({
          message: '提示',
          description: '上传文件不能超过10MB'
        })
        return false
      }
    },
    handleUpload(param, record) {
      const formData = new FormData()
      formData.append('file', param.file)
      formData.append('id', this.form.id)
      this.uploadLoading = true
      importGoodsDetail(formData).then(res => {
        this.$notification['success']({
          message: '提示',
          description: '上传成功'
        })
        this.getEditData(this.form.id);
      }).finally(() => {
        this.uploadLoading = false
      })
    }
  }
}
</script>

<style scoped>
  .field-item {
    position: relative;
    margin-right: 10px;
    margin-top: 10px;
    color: red;
  }
  .field-item_del:hover{
    color: #1890ff;
  }
  .field-item .field-item_del{
    position: absolute;
    top: -5px;
    left: 230px;
    color: #ccc;
    z-index: 999;
    cursor: pointer;
  }
  .box-item{
    width:310px;
  }
  .box-item:last-child{
    width: 410px;
  }
  .box-item .field-item_del{
    left: 300px;
  }
  .ship-item{
    width:200px;
  }
  .ship-item:last-child{
    width: 280px;
  }
  .ship-item .field-item_del{
    left: 190px;
  }
</style>
